import React from 'react';
import { Navigate } from 'react-router-dom';
import { profileMenu } from '../menu';

// eslint-disable-next-line react/prop-types
const SecuredRoute = ({ userData, children }) => {
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return (
		<>
			{/* eslint-disable-next-line no-nested-ternary,react/prop-types */}
			{userData.details === null ? (
				<Navigate
					to={profileMenu.profile.path}
					state={{ warning: 'Please complete your account registration to proceed' }}
				/>
			) : (
				children
			)}
		</>
	);
};

export default SecuredRoute;
