export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		hide: true,
	},
	confirmSignUp: {
		id: 'confirm_register',
		text: 'Confirm your E-mail',
		path: '/account-verification',
		hide: true,
	},
	forgetGroup: {
		id: 'forget',
		text: 'Reset Password',
		hide: true,
		subMenu: {
			forgetPassword: {
				id: 'forgetPassword',
				text: 'Get Help',
				path: '/forgot-password',
				hide: true,
			},
			confirmation: {
				id: 'confirmation',
				text: 'Verify your account',
				path: '/verify-account',
				hide: true,
			},
			resetPassword: {
				id: 'reset',
				text: 'Reset your password now',
				path: '/reset-password',
				hide: true,
			},
		},
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const profileMenu = {
	profile: {
		id: 'profile',
		text: 'Profile',
		path: '/profile',
		icon: 'Person',
		hide: true,
	},
	notification: {
		id: 'notifications',
		text: 'Notifications',
		path: '/notifications',
		icon: 'Bell',
		hide: true,
	},
};

export const loansMenu = {
	loans: {
		id: 'loans',
		text: 'Loans',
		icon: 'Activity',
	},
	publicLoans: {
		id: 'public-loans',
		text: 'Public Sector Loan',
		icon: 'FileBreakFill',
		path: '/public-sector',
		subMenu: {
			loanHistory: {
				id: 'public-loan-history',
				text: 'Loan History',
				icon: 'FileBreak',
				path: '/public-sector/loans-history',
			},
			bookLoan: {
				id: 'public-book-loan',
				text: 'Book Loan',
				icon: 'FileEarmarkDiff',
				path: '/public-sector/loans-apply',
			},
			bookTopUp: {
				id: 'public-topup-loan',
				text: 'Book Top Up',
				icon: 'FileCopy',
				path: '/public-sector/loans-top-up',
				hide: true,
			},
		},
	},
	consumerLoans: {
		id: 'consumer-loans',
		text: 'Consumer Loan',
		icon: 'FileEarmarkPersonFill',
		path: '/consumer-credit',
		subMenu: {
			loanHistory: {
				id: 'consumer-loan-history',
				text: 'Loan History',
				icon: 'FileEarmarkPerson',
				path: '/consumer-credit/loans-history',
			},
			bookLoan: {
				id: 'consumer-book-loan',
				text: 'Book Loan',
				icon: 'FileEarmarkPlus',
				path: '/consumer-credit/loans-apply',
			},
			bookTopUp: {
				id: 'public-topup-loan',
				text: 'Book Top Up',
				icon: 'FileCopy',
				path: '/consumer-credit/loans-top-up',
				hide: true,
			},
		},
	},
};

export const financeMenu = {
	finance: {
		id: 'finance',
		text: 'Finance',
		icon: 'Activity',
	},
	investments: {
		id: 'investments',
		text: 'Investments',
		icon: 'BarChartLineFill',
		path: '/investments',
		subMenu: {
			investmentHistory: {
				id: 'investment-history',
				text: 'Investment History',
				icon: 'BarChartLine',
				path: '/investments/history',
			},
			applyInvestment: {
				id: 'apply-investment',
				text: 'Create Investment',
				icon: 'BarChartSteps',
				path: '/investments/apply',
			},
		},
	},
	savings: {
		id: 'savings',
		text: 'Target Savings',
		icon: 'PieChartFill',
		path: '/target-savings',
	},
};
