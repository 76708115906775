export const links = {
	// bearerUrl: 'https://credite.test/api/v1',
	// galleryUrl: 'https://credite.test/storage',
	bearerUrl: 'https://backend.creditecapital.com/api/v1',
	galleryUrl: 'https://backend.creditecapital.com/storage',
	pages: {
		login: 'auth/login',
		register: 'auth/register',
		verify: 'auth/verify',
		generate_token: 'auth/generate_token',
		forget_password: 'auth/forget',
		reset_password: 'auth/reset',
		listAgents: 'list_agents',
		profile: 'customers/profile',
		profilePhoto: 'customers/profile-photo',
		offers: 'customers/offers',
		processOffer: 'customers/offers/action',
		logout: 'logout',
		loans: 'customers/loans',
		banks: 'list_banks',
		resolve_bank: 'validate_account',
		verifyTransaction: 'customers/tranx/payment_confirmation',
		pools: 'customers/pools',
		bookInvestment: 'customers/invest_now',
		investments: 'customers/investments',
		acceptInvestmenLetter: 'customers/investments/accept-letter',
		savings: 'customers/savings',
		verifyLoan: 'customers/loan-verification',
		topUp: 'customers/loan-topup',
		dashboard: 'customers/dashboard',
		verifyBvn: 'verify_bvn',
		investmentCert: 'customers/investments/preview',
		investmentLetter: 'customers/investments/preview/letter',
	},
	apis: {
		paystack: {
			public_key: 'pk_test_8df7558f5f78d926e1445f3122c268d7009be01a',
			// secret_key: 'FLWSECK-b000a34fce4ff3813a5440d544f343c0-X',
		},
		mono: {
			public_key: 'live_pk_N2Lx6IRNfE6BJWr1TLym',
			// secret_key: 'live_sk_8M1G3dWOYuHNW0gsD7lv'
		},
	},
};

export function getLink(link) {
	return `${links.bearerUrl}/${link}`;
}
